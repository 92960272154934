import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/Homepage';
import Sidebar from './components/Sidebar';
import ChatWindow from './components/ChatWindow';
import MessageInput from './components/MessageInput';
import './App.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


function App() {
  const [chats, setChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [instructionKey, setInstructionKey] = useState(null);
  const [isChatStarted, setIsChatStarted] = useState(false);
  const [explanationText, setExplanationText] = useState('');
  
  const explanations = {
    prescription_renewal: "Here you can get guidelines for taking prescribed medications, dosage adjustments, and possible side effects. Please provide your current prescription details to proceed.",
    symptoms_consultation: "Describe your symptoms and complaints here, and we'll provide appropriate advice and next steps.",
    test_results_examinations: "Discuss and interpret your medical test results. Please provide any necessary details about the tests you've undergone.",
    pregnancy_child_related: "Get advice on pregnancy and children's health. Share relevant information about your condition or your child's symptoms.",
  };

  const instructionText = "To begin, simply type your message in the input area below, and our assistant will guide you further."

  const selectChat = (chat) => {
    setCurrentChat(chat);
    setMessages(chat.messages || []);
    setInstructionKey(chat.instruction_key);

    // Check if this chat has already started
    if (chat.messages && chat.messages.length > 0) {
      setIsChatStarted(true); // Chat has already started
    } else {
      setIsChatStarted(false); // Chat hasn't started yet
      setExplanationText(explanations[chat.instruction_key] || '');
    }
  };

  const prettyPrintSummary = (summary) => {
    let formattedSummary = "Summary:";
    for (const [key, value] of Object.entries(summary)) {
      formattedSummary += `\n\n${key}:\n`;
      value.forEach((item) => {
        formattedSummary += `    ${item};\n`;
      });
    }
    return formattedSummary;
  };

  const fetchSummary = (conversationId) => {
    fetch(`${API_BASE_URL}/summary?conversation_id=${conversationId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      const formattedSummary = prettyPrintSummary(data.summary);

      const summaryMessage = { 
        text: formattedSummary, 
        sender: 'assistant' 
      };

      setMessages((prevMessages) => [
        ...prevMessages,
        summaryMessage,
      ]);

      // Update current chat with the summary message
      const updatedChat = {
        ...currentChat,
        messages: [
          ...currentChat.messages,
          summaryMessage,
        ],
      };

      setCurrentChat(updatedChat);
      setChats((prevChats) => 
        prevChats.map((chat) =>
          chat.conversation_id === updatedChat.conversation_id
            ? updatedChat
            : chat
        )
      );
    })
    .catch((error) => {
      console.error('Error fetching summary:', error);
    });
  };  

  const startNewChat = (key) => {
    setCurrentChat(null);
    setMessages([]);
    setInstructionKey(key);
    setExplanationText(explanations[key]);
    setIsChatStarted(false); // Chat not started yet
  };

  const sendMessage = (message) => {
    if (!message.trim()) return;

    // Trigger chat start on first message
    if (!isChatStarted) {
      setIsChatStarted(true);
    }

    const newMessage = { text: message, sender: 'user' };

    // Immediately add the user's message to the messages state
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    const chatRequest = {
        patient_id: currentChat?.patient_id || '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        conversation_id: currentChat?.conversation_id || null,
        instruction_key: instructionKey || 'symptoms_consultation',
        prompt: message,
    };

    fetch(`${API_BASE_URL}/chat`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(chatRequest),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then((data) => {
        const assistantMessage = { text: data.response_message, sender: 'assistant' };

        setMessages((prevMessages) => [
            ...prevMessages,
            assistantMessage,
        ]);

        const updatedChat = currentChat
            ? {
                ...currentChat,
                messages: [
                    ...currentChat.messages,
                    newMessage,
                    assistantMessage,
                ],
            }
            : {
                conversation_id: data.conversation_id,
                patient_id: chatRequest.patient_id,
                instruction_key: chatRequest.instruction_key,
                messages: [
                    newMessage,
                    assistantMessage,
                ],
            };

        setCurrentChat(updatedChat);
        setChats((prevChats) => {
            if (currentChat) {
                return prevChats.map((chat) =>
                    chat.conversation_id === updatedChat.conversation_id
                        ? updatedChat
                        : chat
                );
            } else {
                return [...prevChats, updatedChat];
            }
        });

        // If generated_summary_identifier is true, fetch and display the summary
        if (data.generated_summary_identifier) {
            fetchSummary(data.conversation_id);
        }
    })
    .catch((error) => {
        console.error('Error during fetch operation:', error);
    });
  };

  return (
    <Router>
      <div className="app">
        <Sidebar chats={chats} selectChat={selectChat} />
        <div className="chat-container">
          <Routes>
            <Route path="/" element={<Homepage startNewChat={startNewChat} />} />
            <Route
              path="/chat"
              element={
                <>
                  <ChatWindow
                    messages={messages}
                    isChatStarted={isChatStarted}
                    explanationText={explanationText}
                    instructionText={instructionText}
                  />
                  <MessageInput sendMessage={sendMessage} />
                </>
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}


export default App;
