import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';

function Sidebar({ chats, selectChat }) {
  const navigate = useNavigate();

  const handleChatSelect = (chat) => {
    selectChat(chat);
    navigate('/chat'); // Ensure navigation to the chat page when a chat is selected
  };

  return (
    <div className="sidebar">
      <button className="home-button" onClick={() => navigate('/')}>Home</button>
      <h2>Chat History</h2>
      <ul>
        {chats.map((chat, index) => (
          <li key={index} onClick={() => handleChatSelect(chat)}>
            {chat.title || `Chat ${index + 1}`}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Sidebar;
