import React from 'react';
import './ChatWindow.css';

function ChatWindow({ messages, isChatStarted, explanationText, instructionText }) {
  return (
    <div className="chat-window">
      <div className="chat-messages">
        {!isChatStarted && (
          <div className="intro-box">
            <h2>Welcome to the Chat</h2>
            <p>{explanationText}</p>
            <div className="empty-line"></div>
            <p>{instructionText}</p>
          </div>
        )}
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            {msg.text.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line.split(' ').map((word, j) => (
                  <React.Fragment key={j}>
                    {word === '' ? '\u00A0' : word}{' '}
                  </React.Fragment>
                ))}
                <br />
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChatWindow;
