import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Homepage.css';

function Homepage({ startNewChat }) {
  const navigate = useNavigate();

  const options = [
    { key: 'prescription_renewal', title: 'Renewal of prescriptions', description: 'Guidelines for taking prescribed medications and dosage adjustments, possible side effects.' },
    { key: 'symptoms_consultation', title: 'Consultations on symptoms and complaints', description: 'Process for renewing current prescriptions, recommendations on vitamins and lifestyle changes.' },
    { key: 'test_results_examinations', title: 'Discussion of analysis and research results', description: 'Recommendations for choosing over-the-counter medications, tips for the safe use of medications.' },
    { key: 'pregnancy_child_related', title: 'Questions related to pregnancy and children\'s health', description: 'Recommendations for choosing over-the-counter medications, tips for the safe use of medications for children.' },
  ];

  const handleOptionClick = (key) => {
    startNewChat(key); // Start a new chat with the selected instruction key
    navigate('/chat'); // Navigate to the chat page
  };

  return (
    <div className="homepage">
      <h1>Reason for your application</h1>
      <div className="options-grid">
        {options.map((option) => (
          <div key={option.key} className="option-card" onClick={() => handleOptionClick(option.key)}>
            <h2>{option.title}</h2>
            <p>{option.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Homepage;
