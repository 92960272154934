import React, { useState } from 'react';
import './MessageInput.css';

function MessageInput({ sendMessage }) {
  const [message, setMessage] = useState('');

  const handleSend = () => {
    if (message.trim()) {
      sendMessage(message);
      setMessage(''); // Clear the input after sending
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default Enter behavior (which would add a new line)
      handleSend(); // Call the send function
    }
  };

  return (
    <div className="message-input">
      <div className="message-input-container">
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder="Type your message..."
          rows={1}
        />
        <button onClick={handleSend}>Send</button>
      </div>
    </div>
  );
}

export default MessageInput;
